import React, {useEffect, useState} from "react";
import Api from "../../Api/api";
import {toast} from "react-toastify";
import {useParams} from "react-router";
import {formatDate} from "../../helper/formatDate";
import {BeatLoader, ClipLoader} from "react-spinners";
import {Link} from "react-router-dom";
import {AiOutlineCloudUpload, AiOutlinePlus} from "react-icons/ai";
import {BiDownload, BiTrash} from "react-icons/bi";
import {AES, enc} from "crypto-js";
import {useStateValue} from "../../states/StateProvider";
import {BsChatLeftText} from "react-icons/bs";

const Documents = () => {
    const [companyName, setCompanyName] = useState('');
    const [loading, setLoading] = useState(false);
    const [showUploadWarning, setShowUploadWarning] = useState(false);
    const [deleteClicked, setDeleteClicked] = useState(null)
    const [saving, setSaving] = useState(null);
    const [downloading, setDownloading] = useState(null);
    const [deleting, setDeleting] = useState(null);
    const [Deleting, setDeletingState] = useState(false)
    const [data, setData] = useState([]);
    const [modalIsOpen, setModalIsOpen] = useState(false);

    const [file, setFile] = useState(null);
    const [newDocName, setNewDocName] = useState(null);
    const [comment, setComment] = useState('');
    const [selectedId, setSelectedId] = useState(null);
    const [selectedName, setSelectedName] = useState(null);
    const [creatingNewDoc, setCreatingNewDoc] = useState(false);
    const [loadingSaveNewDoc, setLoadingSaveNewDoc] = useState(false);

    const [{secretKey}] = useStateValue()
    const decryptedBytes = localStorage.getItem('user') ? AES.decrypt(localStorage.getItem('user'), secretKey) : false;
    const user = JSON.parse(decryptedBytes.toString(enc.Utf8))

    const param = useParams();

    useEffect(() => {
        Api().get(`getCompanyName/${param.company}`).then(res => {
            setCompanyName(res.data)
        })
        getData().then(r => r)
    }, [param]);

    async function getData() {
        setLoading(true);
        await Api().get(`/sp_getDataDocuments/${param.portal}/${param.company}`)
            .then(res => {
                setData(res.data);
            })
            .catch(e => {
                toast.error('Firmendetails konnten nicht geladen werden!');
            })
        setLoading(false);
    }

    async function downloadDoc(id, name) {

        setDownloading(id)

        let downloadData = new FormData();
        downloadData.append('portal', param.portal);
        downloadData.append('fpID', param.company);
        downloadData.append('docID', id);

        await Api().post('fn_getDocument', downloadData, {
            responseType: 'blob',
        }).then(async response => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', name);
            document.body.appendChild(link);
            link.click();
        });

        setDownloading(null)
    }

    function uploadFileToBackend(file, id, comment, name, creatingNew) {

        const reader = new FileReader();
        setSaving(id)

        if (creatingNew) {
            setLoadingSaveNewDoc(true)
        }
        reader.onload = async function (event) {
            const formData = new FormData();
            formData.append('portal', param.portal);
            formData.append('fpID', param.company);
            formData.append('docID', id);
            formData.append('docName', name);
            formData.append('comment', comment);
            formData.append('user', user.ID);
            formData.append('filename', file.name);
            formData.append('file', file);

            await Api().post('sp_putDocument', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
                .then(res => {
                    closeModal()
                    getData()
                    setLoadingSaveNewDoc(true)
                    toast.success('Daten erfolgreich aktualisiert')
                    setLoadingSaveNewDoc(false)
                })
                .catch(error => {
                    toast.error('Etwas ist schief gelaufen')
                    console.error('Error sending file to backend:', error);
                    setLoadingSaveNewDoc(false)
                })
        };

        reader.readAsBinaryString(file);
    }


    function chckIfModalUploadShouldOpen(id, fileName, docName) {

        setSelectedId(id);
        setSelectedName(docName);

        if (fileName) {
            setShowUploadWarning(true)
        } else {
            setModalIsOpen(true)
            setSaving(id)
        }
    }

    function openModal(thirdSection) {
        if (!saving || thirdSection) {
            setShowUploadWarning(false);
            setModalIsOpen(true);
            setSaving(selectedId)
        }

        if (thirdSection) {
            setCreatingNewDoc(true)
        }
    }

    function closeModal() {
        setModalIsOpen(false);
        setSaving(null)
        setFile(null);
        setComment('');
        setCreatingNewDoc(false);
        setNewDocName('');
        setSelectedName('');
        setSelectedId(null);
    }

    function handleFileChange(e) {
        setFile(e.target.files[0]);
    }

    function handleDocNameChange(e) {
        setNewDocName(e.target.value);
    }

    function handleCommentChange(e) {
        setComment(e.target.value);
    }

    function handleSubmit() {

        let newID

        if (data.thirdSection?.length > 0) {
            newID = Number(data.thirdSection[data.thirdSection?.length - 1].ID) + 1
        } else {
            newID = 101
        }

        if (file && (!creatingNewDoc || (creatingNewDoc && newDocName))) {
            uploadFileToBackend(file, selectedId ?? newID, comment, newDocName || selectedName, creatingNewDoc);
            setModalIsOpen(false);
        } else {
            toast.error('Bitte laden Sie eine Datei hoch');
        }
    }

    async function removeDoc() {

        setDeleting(deleteClicked)
        setDeletingState(true)
        await Api().get(`/sp_deleteDocument/${param.portal}/${param.company}/${deleteClicked}`).then(res => {
            toast.success('Dokument erfolgreich gelöscht')
        }).catch(e => {
            toast.error('Etwas ist schief gelaufen!')
        }).finally(() => {
            getData()
        })

        setDeletingState(false)
        setDeleting(null)
        setDeleteClicked(null)
    }

    return (
        <div className='dashboardContainer'>
            <h2 className='text-2xl lg:text-left'>
                Dateinachweise
                <span> {('für ' + companyName) || <BeatLoader size={'8px'}/>}</span>
            </h2>
            <div className='float-right -mt-4'>
                <Link
                    to={{
                        pathname: `/firmenprojekte/${param.portal}/${param.company}`,
                    }}
                >
                    <span className='bg-mainBlue px-5 py-2 rounded-lg text-white'>Zurück</span>

                </Link>
            </div>
            <p
                className={`${(!showUploadWarning) && 'hideDiv'} shadow-sm md:w-96 w-11/12 shadow-text text-lg px-6 py-6  flex flex-col rounded-lg z-10 absolute bg-offWhite centerItemsAbsolute`}>
                <span>Beim Bestätigen wird das bisher bestehende Dokument ersatzlos überschrieben. Wenn dieser Schritt durchgeführt werden soll, bitte mit "OK" bestätigen. Andernfalls bitte das Dokument vorher downloaden und als zusätzliches Dokument im unteren Teil der Seite speichern</span>
                <span
                    className={`flex justify-start px-24 pt-5 text-sm text-md font-bold`}>
                    <button onClick={() => openModal(false)}
                            className='bg-green mr-3 text-white px-5 hover:bg-white hover:text-green py-2 rounded-xl'>OK
                    </button>
                    <button onClick={() => {
                        setShowUploadWarning(false)
                        closeModal()
                    }}
                            className='bg-cancel hover:bg-white hover:text-cancel text-white px-5 py-2 rounded-xl'>Abbrechen
                    </button>
                </span>
            </p>
            <div className='overflow-x-auto mt-6'>
                <div>
                    {
                        loading ?
                            <div className='centerItemsAbsolute'>
                                <ClipLoader size={'40px'} color={'black'}/>
                            </div>
                            :
                            <>
                                <table className='min-w-full text-left bg-white rounded-lg inheritTextTransform'>
                                    <thead className="whitespace-nowrap border-y border-silver border-x-0">
                                    <tr className='text-sm text-grey pl-1.5 font-bold h-12 bg-whiteDark'>
                                        <th className="text-sm text-grey pl-1.5 w-80">Dateinachweis zu (max. 50 MB pro Upload)</th>
                                        <th className="text-sm text-grey pl-1.5 w-8"></th>
                                        <th className="text-sm text-grey pl-1.5 w-8"></th>
                                        <th className="text-sm text-grey pl-1.5 w-8"></th>
                                        <th className="text-sm text-grey pl-1.5">Hochgeladen am</th>
                                        <th className="text-sm text-grey pl-1.5 w-60"> von</th>
                                        <th className="text-sm text-grey pl-1.5 ">Dateiname</th>
                                        <th className="text-sm text-grey pl-1.5 w-1">Kommentar</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr className={`${(deleteClicked || showUploadWarning) && 'overlay'}`}/>
                                    {data.firstSection?.map(d => (
                                        <tr key={d.ID} className='border-y border-silver border-x-0 text-sm'>
                                            <td style={{fontSize:'16px'}}>{d.Name}</td>
                                            <td>
                                                <button
                                                    onClick={() => chckIfModalUploadShouldOpen(d.ID, d.Dateiname, d.Name)}
                                                    className={`bg-mainBlue px-2 py-1 text-white rounded-lg ${!saving ? '' : 'w-full cursor-no-drop bg-lightBlue'}`}
                                                >
                                                    {
                                                        Number(saving) === Number(d.ID) ?
                                                            <ClipLoader color='white' size='8px'/> :
                                                            <section
                                                                className='flex justify-center content-center items-center gap-1'>
                                                                <AiOutlineCloudUpload/>
                                                                <span>Hochgeladen</span>
                                                            </section>
                                                    }
                                                </button>
                                            </td>
                                            <td>
                                                <button
                                                    onClick={() => downloadDoc(d.ID, d.Dateiname)}
                                                    className={`${downloading !== null ? 'cursor-no-drop' : ''} w-full px-4 py-1 text-white rounded-lg cursor-pointer  ${d.Upload_Datum ? 'bg-green' : 'bg-grey cursor-no-drop'}`}
                                                    disabled={downloading !== null || !d.Upload_Datum}
                                                >
                                                    {
                                                        Number(downloading) === Number(d.ID) ?
                                                            <ClipLoader color='white' size='8px'/>
                                                            :
                                                            <section
                                                                className='flex justify-center content-center items-center gap-1'>
                                                                <BiDownload/>
                                                                <span>Herunterladen</span>
                                                            </section>
                                                    }
                                                </button>
                                            </td>
                                            <td
                                                className={`${(!deleteClicked) && 'hideDiv'} shadow-sm md:w-96 w-11/12 shadow-text text-lg px-6 py-6  flex flex-col rounded-lg z-10 absolute bg-offWhite centerItemsAbsolute`}>
                                                <span>Das ausgewählte Dokument wird unwiderruflich gelöscht. Sollte eine Kopie davon benötigt werden, bitte zunächst abbrechen und das Dokument per Download sichern.</span>
                                                <span
                                                    className={`${Deleting && 'hideDiv'} flex justify-start px-24 pt-5 text-sm text-md font-bold`}>
                                                    <button onClick={removeDoc}
                                                            className='bg-green mr-3 text-white px-5 hover:bg-white hover:text-green py-2 rounded-xl'>OK
                                                    </button>
                                                    <button onClick={() => setDeleteClicked(null)}
                                                            className='bg-cancel hover:bg-white hover:text-cancel text-white px-5 py-2 rounded-xl'>Abbrechen
                                                    </button>
                                                </span>
                                                <span className='mx-auto'>
                                                    {Deleting && <ClipLoader size={10} color='#3A46A9'/>}
                                                </span>
                                            </td>
                                            <td className={`${(deleteClicked) && 'hideDiv'}`}>
                                                <button
                                                    className={`${deleting !== null ? 'cursor-no-drop' : ''}w-full px-4 py-1 text-white rounded-lg cursor-pointer ${d.Upload_Datum ? 'bg-cancel' : 'bg-grey cursor-no-drop'}`}
                                                    disabled={deleting !== null || !d.Upload_Datum}
                                                    onClick={() => setDeleteClicked(d.ID)}
                                                >
                                                    <section
                                                        className='flex justify-center content-center items-center gap-1'>
                                                        <BiTrash size={'20px'}/>
                                                        <span>Löschen</span>
                                                    </section>
                                                </button>
                                            </td>
                                            <td>{formatDate(d.Upload_Datum, true)}</td>
                                            <td>{d.Upload_von}</td>
                                            <td className="tooltip text-gray-900 font-light">
                                                {d.Dateiname && d.Dateiname.length > 50 ? `${d.Dateiname.substring(0, 50)}...` : d.Dateiname}
                                                <span className={`${d.Dateiname?.length > 50 ? 'tooltiptextInstantRight' : 'hidden'}`}>
                                                    {d.Dateiname}
                                                </span>
                                            </td>
                                            <td className="tooltip text-gray-900 font-light">
                                                <BsChatLeftText size='16px'/>
                                                <span className={`${d.Kommentar ? 'tooltiptext' : 'hidden'}`}>
                                                    {d.Kommentar}
                                                </span>
                                            </td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </table>

                                {
                                    data.secondSection?.length > 0 &&
                                    <table className='min-w-full text-left bg-white rounded-lg'>
                                        <thead className="whitespace-nowrap border-y border-silver border-x-0">
                                        <tr className='text-sm text-grey pl-1.5 font-bold h-12 bg-whiteDark'>
                                            <th className="text-sm text-grey pl-1.5 w-80">Optionale Dateinachweise (max. 50 MB pro Upload)</th>
                                            <th className="text-sm text-grey pl-1.5 w-8"></th>
                                            <th className="text-sm text-grey pl-1.5 w-8"></th>
                                            <th className="text-sm text-grey pl-1.5 w-8"></th>
                                            <th className="text-sm text-grey pl-1.5">Hochgeladen am</th>
                                            <th className="text-sm text-grey pl-1.5 w-60">von</th>
                                            <th className="text-sm text-grey pl-1.5 ">Dateiname</th>
                                            <th className="text-sm text-grey pl-1.5 w-1">Kommentar</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {data.secondSection?.map(d => (
                                            <tr key={d.ID} className='border-y border-silver border-x-0 text-sm'>
                                                <td style={{fontSize:'16px'}}>{d.Name}</td>
                                                <td>
                                                    <button
                                                        onClick={() => chckIfModalUploadShouldOpen(d.ID, d.Dateiname, d.Name)}
                                                        className={`bg-mainBlue px-2 py-1 text-white rounded-lg ${!saving ? '' : 'w-full cursor-no-drop bg-lightBlue'}`}
                                                    >
                                                        {
                                                            Number(saving) === Number(d.ID) ?
                                                                <ClipLoader color='white' size='8px'/> :
                                                                <section
                                                                    className='flex justify-center content-center items-center gap-1'>
                                                                    <AiOutlineCloudUpload/>
                                                                    <span>Hochgeladen</span>
                                                                </section>
                                                        }
                                                    </button>
                                                </td>
                                                <td>
                                                    <button
                                                        className={`${downloading !== null ? 'cursor-no-drop' : ''}w-full px-4 py-1 text-white rounded-lg cursor-pointer ${d.Upload_Datum ? 'bg-green' : 'bg-grey cursor-no-drop'}`}
                                                        disabled={downloading !== null || !d.Upload_Datum}
                                                        onClick={() => downloadDoc(d.ID, d.Dateiname)}
                                                    >
                                                        {
                                                            Number(downloading) === Number(d.ID) ?
                                                                <ClipLoader color='white' size='8px'/> :
                                                                <section
                                                                    className='flex justify-center content-center items-center gap-1'>
                                                                    <BiDownload/>
                                                                    <span>Herunterladen</span>
                                                                </section>
                                                        }
                                                    </button>
                                                </td>
                                                <td
                                                    className={`${(!deleteClicked) && 'hideDiv'} shadow-sm md:w-96 w-11/12 shadow-text text-lg px-6 py-6  flex flex-col rounded-lg z-10 absolute bg-offWhite centerItemsAbsolute`}>
                                                    <span>Das ausgewählte Dokument wird unwiderruflich gelöscht. Sollte eine Kopie davon benötigt werden, bitte zunächst abbrechen und das Dokument per Download sichern.</span>
                                                    <span
                                                        className={`${Deleting && 'hideDiv'} flex justify-start px-24 pt-5 text-sm text-md font-bold`}>
                                                    <button onClick={removeDoc}
                                                            className='bg-green mr-3 text-white px-5 hover:bg-white hover:text-green py-2 rounded-xl'>OK
                                                    </button>
                                                    <button onClick={() => setDeleteClicked(null)}
                                                            className='bg-cancel hover:bg-white hover:text-cancel text-white px-5 py-2 rounded-xl'>Abbrechen
                                                    </button>
                                                </span>
                                                    <span className='mx-auto'>
                                                    {Deleting && <ClipLoader size={10} color='#3A46A9'/>}
                                                </span>
                                                </td>
                                                <td className={`${(deleteClicked) && 'hideDiv'}`}>
                                                    <button
                                                        className={`${deleting !== null ? 'cursor-no-drop' : ''}w-full px-4 py-1 text-white rounded-lg cursor-pointer ${d.Upload_Datum ? 'bg-cancel' : 'bg-grey cursor-no-drop'}`}
                                                        disabled={deleting !== null || !d.Upload_Datum}
                                                        onClick={() => setDeleteClicked(d.ID)}
                                                    >
                                                        <section
                                                            className='flex justify-center content-center items-center gap-1'>
                                                            <BiTrash size={'20px'}/>
                                                            <span>Löschen</span>
                                                        </section>
                                                    </button>
                                                </td>
                                                <td>{formatDate(d.Upload_Datum, true)}</td>
                                                <td>{d.Upload_von}</td>
                                                <td className="tooltip text-gray-900 font-light">
                                                    {d.Dateiname && d.Dateiname.length > 50 ? `${d.Dateiname.substring(0, 50)}...` : d.Dateiname}
                                                    <span className={`${d.Dateiname?.length>50 ? 'tooltiptextInstantRight' : 'hidden'}`}>
                                                    {d.Dateiname}
                                                </span>
                                                </td>
                                                <td className="tooltip text-gray-900 font-light">
                                                    <BsChatLeftText size='16px'/>
                                                    <span className={`${d.Kommentar ? 'tooltiptext' : 'hidden'}`}>
                                                    {d.Kommentar}
                                                </span>
                                                </td>
                                            </tr>
                                        ))}
                                        </tbody>
                                    </table>
                                }

                                {
                                    data.thirdSection?.length > 0 &&

                                    <table className='min-w-full text-left bg-white rounded-lg'>
                                        <thead className="whitespace-nowrap border-y border-silver border-x-0">
                                        <tr className='text-sm text-grey pl-1.5 font-bold h-12 bg-whiteDark'>
                                            <th className="text-sm text-grey pl-1.5 w-80">Dateinachweis zu (max. 50 MB pro Upload)</th>
                                            <th className="text-sm text-grey pl-1.5 w-8"></th>
                                            <th className="text-sm text-grey pl-1.5 w-8"></th>
                                            <th className="text-sm text-grey pl-1.5 w-8"></th>
                                            <th className="text-sm text-grey pl-1.5">Hochgeladen am</th>
                                            <th className="text-sm text-grey pl-1.5 w-60">von</th>
                                            <th className="text-sm text-grey pl-1.5 ">Dateiname</th>
                                            <th className="text-sm text-grey pl-1.5 w-1">Kommentar</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {data.thirdSection?.map(d => (
                                            <tr key={d.ID} className='border-y border-silver border-x-0 text-sm'>
                                                <td style={{fontSize:'16px'}} className="tooltip text-gray-900 font-light">
                                                    {d.Name && d.Name.length > 33 ? `${d.Name.substring(0, 32)}...` : d.Name}
                                                    <span className={`${d.Name.length > 33 ? 'tooltiptextInstantRight' : 'hidden'}`}>
                                                    {d.Name}
                                                </span>
                                                </td>
                                                <td>
                                                    <button
                                                        onClick={() => chckIfModalUploadShouldOpen(d.ID, d.Dateiname, d.Name)}
                                                        className={`bg-mainBlue px-2 py-1 text-white rounded-lg ${!saving ? '' : 'w-full cursor-no-drop bg-lightBlue'}`}
                                                    >
                                                        {
                                                            Number(saving) === Number(d.ID) ?
                                                                <ClipLoader color='white' size='8px'/> :
                                                                <section
                                                                    className='flex justify-center content-center items-center gap-1'>
                                                                    <AiOutlineCloudUpload/>
                                                                    <span>Hochgeladen</span>
                                                                </section>
                                                        }
                                                    </button>
                                                </td>
                                                <td>
                                                    <button
                                                        className={`${downloading !== null ? 'cursor-no-drop' : ''} w-full px-4 py-1 text-white rounded-lg cursor-pointer ${d.Upload_Datum ? 'bg-green' : 'bg-grey cursor-no-drop'}`}
                                                        disabled={downloading !== null || !d.Upload_Datum}
                                                        onClick={() => downloadDoc(d.ID, d.Dateiname)}
                                                    >
                                                        {
                                                            Number(downloading) === Number(d.ID) ?
                                                                <ClipLoader color='white' size='8px'/> :
                                                                <section
                                                                    className='flex justify-center content-center items-center gap-1'>
                                                                    <BiDownload/>
                                                                    <span>Herunterladen</span>
                                                                </section>
                                                        }
                                                    </button>
                                                </td>
                                                <td
                                                    className={`${(!deleteClicked) && 'hideDiv'} shadow-sm md:w-96 w-11/12 shadow-text text-lg px-6 py-6  flex flex-col rounded-lg z-10 absolute bg-offWhite centerItemsAbsolute`}>
                                                    <span>Das ausgewählte Dokument wird unwiderruflich gelöscht. Sollte eine Kopie davon benötigt werden, bitte zunächst abbrechen und das Dokument per Download sichern.</span>
                                                    <span
                                                        className={`${Deleting && 'hideDiv'} flex justify-start px-24 pt-5 text-sm text-md font-bold`}>
                                                    <button onClick={removeDoc}
                                                            className='bg-green mr-3 text-white px-5 hover:bg-white hover:text-green py-2 rounded-xl'>OK
                                                    </button>
                                                    <button onClick={() => setDeleteClicked(null)}
                                                            className='bg-cancel hover:bg-white hover:text-cancel text-white px-5 py-2 rounded-xl'>Abbrechen
                                                    </button>
                                                </span>
                                                    <span className='mx-auto'>
                                                    {Deleting && <ClipLoader size={10} color='#3A46A9'/>}
                                                </span>
                                                </td>
                                                <td className={`${(deleteClicked) && 'hideDiv'}`}>
                                                    <button
                                                        className={`${deleting !== null ? 'cursor-no-drop' : ''}w-full px-4 py-1 text-white rounded-lg cursor-pointer ${d.Upload_Datum ? 'bg-cancel' : 'bg-grey cursor-no-drop'}`}
                                                        disabled={deleting !== null || !d.Upload_Datum}
                                                        onClick={() => setDeleteClicked(d.ID)}
                                                    >
                                                        <section
                                                            className='flex justify-center content-center items-center gap-1'>
                                                            <BiTrash size={'20px'}/>
                                                            <span>Löschen</span>
                                                        </section>
                                                    </button>
                                                </td>
                                                <td>{formatDate(d.Upload_Datum, true)}</td>
                                                <td>{d.Upload_von}</td>
                                                <td className="tooltip text-gray-900 font-light">
                                                    {d.Dateiname && d.Dateiname.length > 50 ? `${d.Dateiname.substring(0, 50)}...` : d.Dateiname}
                                                    <span className={`${d.Dateiname?.length > 50 ? 'tooltiptextInstantRight' : 'hidden'}`}>
                                                    {d.Dateiname}
                                                </span>
                                                </td>
                                                <td className="tooltip text-gray-900 font-light">
                                                    <BsChatLeftText size='16px'/>
                                                    <span className={`${d.Kommentar ? 'tooltiptext' : 'hidden'}`}>
                                                    {d.Kommentar}
                                                </span>
                                                </td>
                                            </tr>
                                        ))}
                                        </tbody>
                                    </table>
                                }

                                {
                                    loadingSaveNewDoc &&
                                    <div className='flex items-center justify-center mt-5 '>
                                        <h2 className='mr-1'>Es wird ein neues optionales Dokument erstellt. Bitte warten </h2>
                                        <BeatLoader size={'8px'}/>
                                    </div>
                                }
                                <button
                                    className='px-4 py-2 my-7 bg-mainBlue text-white hover:bg-lightBlue rounded-lg'
                                    onClick={() => openModal(true)}>
                                    <section className='flex justify-center items-center'>
                                        <AiOutlinePlus size='20px' color='#eeeeee'/>
                                        <span> Neues Dokument hinzufügen</span>
                                    </section>
                                </button>

                                {modalIsOpen && (
                                    <div className="modal-overlay">
                                        <div className="modal">
                                            <div className='modal-content-small px-44'>
                                                <br/>
                                                <br/>
                                                <br/>
                                                <h2 className='text-xl text-left ml-28'>Dokument hochladen</h2>
                                                <hr className='w-44 ml-28'/>
                                                <br/>
                                                {
                                                    creatingNewDoc &&
                                                    <input className='w-8/12' type='text' onChange={handleDocNameChange}
                                                           value={newDocName}
                                                           placeholder='Bitte einen Namen für das Dokument vergeben'
                                                    />
                                                }
                                                <div>
                                                    <input className='my-2 p-3 w-8/12' type='file'
                                                           onChange={handleFileChange}/>
                                                </div>

                                                <div>
                                                <textarea
                                                    className='w-8/12 border border-whiteDark my-2 p-3'
                                                    placeholder='Zusätzlichen Kommentar hinzufügen'
                                                    value={comment}
                                                    onChange={handleCommentChange}/>
                                                </div>
                                                <div className='flex gap-2 justify-center content-center'>
                                                    <button onClick={handleSubmit}
                                                            className='bg-mainBlue px-4 py-3 text-white w-4/12 rounded-md'>
                                                        Speichern
                                                    </button>
                                                    <button onClick={closeModal}
                                                            className='bg-cancel px-4 py-3 text-white w-4/12 rounded-md'>
                                                        Stornieren
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </>
                    }
                </div>
            </div>
        </div>
    );
};

export default Documents;
