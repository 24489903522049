export const UpsellingHeadersDGG = [
    {
        id: 1,
        title: 'Firma'
    },
    {
        id: 2,
        title: 'Closer'
    },
    {
        id: 3,
        title: 'bAV'
    },
    {
        id: 4,
        title: 'CLS'
    },
    {
        id: 5,
        title: 'GehaltsExtras'
    },
    {
        id: 6,
        title: 'bKV'
    },
    {
        id: 7,
        title: 'bBU',
    },
    {
        id: 8,
        title: 'HRMT Pro'
    },
    // {
    //     id: 8,
    //     title: 'Upselling (Ja/Nein)'
    // },
    // {
    //     id: 8,
    //     title: 'NBG'
    // },

]