import * as React from 'react';
import {useEffect, useState} from 'react';
import Api from "../../Api/api";
import {toast} from "react-toastify";
import {formatDate} from "../../helper/formatDate";
import {IoArrowBackSharp} from 'react-icons/io5';
import {Link, useLocation, useNavigate} from "react-router-dom";
import {ClipLoader} from "react-spinners";
import {AES, enc} from "crypto-js";
import {useStateValue} from "../../states/StateProvider";
import Pagination from "../../components/pagination";
import {RiArrowDownSFill, RiArrowUpSFill} from "react-icons/ri";

const MailHistory = () => {

    const [{
        sortMethodWVHistory,
        sortColumnWVHistory,
        secretKey,
        pageWvHistory,
        filterWVHistory,
        filterIDWVHistory
    }, dispatch] = useStateValue();

    const WVHistorieHeaders = [
        {
            id: 1,
            title: 'Ersteller'
        },
        {
            id: 2,
            title: 'Erstellungs Datum'
        },
        {
            id: 3,
            title: 'Firma'
        },
        {
            id: 4,
            title: 'Grund'
        },
        {
            id: 5,
            title: 'Inhalt'
        },
        {
            id: 6,
            title: 'WV Datum'
        },
    ]

    const sortableFields = [1, 2, 3, 4, 5, 6]
    const searChableFields = [1, 3, 4, 5]
    const [data, setData] = useState([])
    const [noDataFound, setNoDataFound] = useState(false)
    const [loading, setLoading] = useState(true)
    const [rows, setRows] = useState('10');
    const navigate = useNavigate();
    const location = useLocation();
    const [total, setTotal] = useState(0);
    let PageSize = rows;

    useEffect(() => {
        try {
            const decryptedBytes = localStorage.getItem('user') ? AES.decrypt(localStorage.getItem('user'), secretKey) : false;
            const User = JSON.parse(decryptedBytes.toString(enc.Utf8))
            if (User.role !== 'Internal') {
                location.replace('/')
            } else {
                const delayQuery = setTimeout(async () => {
                    setLoading(true)
                    let data = new FormData()
                    data.append('perPage', rows)
                    data.append('page', pageWvHistory)
                    data.append('sortMethod', sortMethodWVHistory)
                    data.append('sortColumn', sortColumnWVHistory)
                    data.append('filterID', JSON.stringify(filterIDWVHistory))
                    data.append('filter', JSON.stringify(filterWVHistory))

                    Api().post('/sp_getDataWVHistorie', data).then(res => {
                        setData(res.data)
                        Object.values(res.data[0]).map(r=>{
                            if(r==='0'){
                                setNoDataFound(true)
                            }else{
                                setNoDataFound(false)
                            }
                        })

                        setTotal(res.data[0]?.total)
                        setLoading(false)
                    }).catch(e => {
                        toast.error('etwas ist schief gelaufen!')
                    })
                }, filterWVHistory ? 1500 : 0)

                return () => clearTimeout(delayQuery)
            }
        } catch (e) {
            window.location.replace('/anmeldung')
        }

    }, [pageWvHistory, rows, sortMethodWVHistory, sortColumnWVHistory, filterWVHistory, filterIDWVHistory]);

    function setPageStates(e) {
        dispatch({type: "SET_PAGE_WVHISTORY", item: 1})
        setRows(e.target.value)
    }

    function ascSort(id) {
        dispatch({type: "SET_SORT_COLUMN_WVHISTORY", item: id})
        dispatch({type: "SET_SORT_WVHISTORY_METHOD", item: 'asc'})
    }

    function descSort(id) {
        dispatch({type: "SET_SORT_COLUMN_WVHISTORY", item: id})
        dispatch({type: "SET_SORT_WVHISTORY_METHOD", item: 'desc'})
    }

    function enableFilter(id, val) {
        dispatch({type: "SET_PAGE_WVHISTORY", item: 1})
        if (id === 1) {
            dispatch({type: "SET_SORTWVHISTORYFILTER", item: {...filterWVHistory, a: val}})
            dispatch({type: "SET_SORTWVHISTORYFILTERID", item: {...filterIDWVHistory, a: 1}})
        }
        if (id === 3) {
            dispatch({type: "SET_SORTWVHISTORYFILTER", item: {...filterWVHistory, c: val}})
            dispatch({type: "SET_SORTWVHISTORYFILTERID", item: {...filterIDWVHistory, c: 3}})
        }
        if (id === 4) {
            dispatch({type: "SET_SORTWVHISTORYFILTER", item: {...filterWVHistory, d: val}})
            dispatch({type: "SET_SORTWVHISTORYFILTERID", item: {...filterIDWVHistory, d: 4}})
        }
        if (id === 5) {
            dispatch({type: "SET_SORTWVHISTORYFILTER", item: {...filterWVHistory, e: val}})
            dispatch({type: "SET_SORTWVHISTORYFILTERID", item: {...filterIDWVHistory, e: 5}})
        }
    }

    return (
        <div className='dashboardContainer'>
            <h2 className='text-2xl lg:text-left'> WV-Historie</h2>
            <div className='float-right'>
                <a title='vorherige Seite' onClick={() => navigate(-1)} className='cursor-pointer'>
                    <IoArrowBackSharp size={'30px'}/>
                </a>
            </div>
            {
                loading ?
                    <div className='centerItemsAbsolute'>
                        <ClipLoader size={'40px'} color={'black'}/>
                    </div>
                    :
                    <table className='min-w-full text-left bg-white'>
                        <thead
                            className="whitespace-nowrap bg-white border-y border-silver border-x-0 sticky top-0">
                        <tr className='text-sm text-grey pl-1.5 font-bold'>
                            {
                                WVHistorieHeaders?.map(header => (
                                    <th key={header.id} scope="col" className="text-sm text-grey pl-1.5 tooltip"
                                        style={{minWidth: searChableFields.includes(header.id) ? '8rem' : 'fit-content'}}
                                    >
                                        <span className='flex justify-left'>
                                            <span
                                                className={`tooltip mt-1.5 text-center xl:h-fit lg:h-14 ${sortColumnWVHistory === header.id && 'text-mainBlue'}`}
                                            >
                                                {header.title}
                                            </span>
                                            <span
                                                className={`${!(sortableFields.includes(header.id)) && 'opacity-0'}`}>
                                                <p className={`cursor-pointer ${sortColumnWVHistory === header.id && sortMethodWVHistory === 'asc' ? 'text-mainBlue' : ''}`}
                                                   onClick={() => ascSort(header.id)}
                                                >
                                                    <RiArrowUpSFill size='22px'/>
                                                </p>
                                                <p className={`-mt-3.5 cursor-pointer ${sortColumnWVHistory === header.id && sortMethodWVHistory === 'desc' ? 'text-mainBlue' : ''}`}
                                                   onClick={() => descSort(header.id)}
                                                >
                                                    <RiArrowDownSFill size='22px'/>
                                                </p>
                                            </span>
                                        </span>
                                        <span
                                            className={`${!(searChableFields.includes(header.id)) && 'hideDiv'}`}>
                                                <input className={`w-full h-2 px-2 py-3 search mb-4`}
                                                       type='text'
                                                       maxLength="50"
                                                       value={header.id === 1 ? filterWVHistory.a: header.id === 3 ? filterWVHistory.c : header.id === 4 ? filterWVHistory.d : filterWVHistory.e}
                                                       onChange={(e) => enableFilter(header.id, e.target.value)}
                                                       placeholder='Suche...'
                                                />
                                        </span>
                                        <span  className={`${header.title==='MA' && 'opacity-0'}`}>
                                            <input className='w-full mb-4 opacity-0' type='text'
                                            />
                                        </span>
                                    </th>
                                ))
                            }
                        </tr>
                        </thead>
                        <tbody>
                        {
                            data?.map(d => (
                                <tr className='border-y border-silver border-x-0 text-sm' key={d.FP_ID}>
                                    <td className='text-gray-900 font-light pr-3'>
                                        {d.Ersteller}
                                    </td>
                                    <td className='text-gray-900 font-light pr-3'>
                                        {formatDate(d.Erstellungs_Datum, false)}
                                    </td>
                                    <td className='text-mainBlue font-light pr-3'>
                                        <Link target='_blank' to={`/firmenprojekte/${d.module}/${d.FP_ID}`}>
                                            {d.Firma}
                                        </Link>
                                    </td>
                                    <td className='text-gray-900 font-light pr-3'>
                                        {d.Grund}
                                    </td>
                                    <td className='text-gray-900 font-light pr-3'>
                                        {d.Inhalt}
                                    </td>
                                    <td className='text-gray-900 font-light pr-3'>
                                        {formatDate(d.WV_Datum, false)}
                                    </td>
                                </tr>
                            ))
                        }
                        </tbody>
                        {
                            noDataFound &&
                            <h3 className='centerItemsAbsolute'>Keine Daten gefunden</h3>
                        }
                    </table>
            }
            <div className={`flex justify-end gap-20 ${loading && 'opacity-0'}`}>
                <div className={`mt-10 ${loading && 'opacity-0'}`}>
                    {
                        data.length > 0 &&
                        <Pagination
                            className="pagination-bar"
                            currentPage={pageWvHistory}
                            totalCount={total??10}
                            pageSize={PageSize??1}
                            onPageChange={pageNo => dispatch({
                                type: "SET_PAGE_WVHISTORY",
                                item: pageNo
                            })}
                        />
                    }
                </div>
                <p className={`${(data?.length === 0) && 'hideDiv'} mr-2 text-sm text-grey ml-10 mt-10`}>
                    {pageWvHistory === 1 ? pageWvHistory : (1 + (Number(rows) * pageWvHistory)) - Number(rows)} bis {(data?.length < Number(rows)) ? data.length + Number(rows) < total ? data.length + (Number(rows) * pageWvHistory) - Number(rows) : total : (Number(rows) + (Number(rows) * pageWvHistory)) - Number(rows)} von {total} Einträge
                </p>
                <h2 className={`${(data?.length === 0) && 'hideDiv'}  text-sm text-grey mt-10`}>
                    Einträge anzeigen:
                    <span>
                        <select onChange={setPageStates} className={` bg-transparent text-mainBlue`}>
                            <option value={'10'}>{10}</option>
                            <option value={'25'}>{25}</option>
                            <option value={'10000'}>Alle</option>
                        </select>
                    </span>
                </h2>
            </div>
        </div>
    );
};

export default MailHistory